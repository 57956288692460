



























































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import { getDirective } from 'vue-debounce'

@Component({
  directives: {
    debounce: getDirective()
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  }
})
export default class EmployeeUsers extends Vue {
  page = 1
  pageLength = 10
  totalItems = 0
  columns = [
    {
      label: 'Name',
      field: 'fullName',
    },
    {
      label: 'Email',
      field: 'email',
    }
  ]
  rows = []
  searchTerm = ''

  async created() {
    await this.getItems();
  }

  async getItems() {
    let data;
    if(this.searchTerm != null && this.searchTerm != "") {
      data = await this.$api.employee.user.GetList(this.page, this.pageLength, this.searchTerm);
    }else{
      data = await this.$api.employee.user.GetList(this.page, this.pageLength);
    }
    this.$set(this, "rows", data.items);
    this.$set(this, "totalItems", data.totalItems);
  }

  @Watch('page')
  async watchPage() {
    await this.getItems();
  }

  @Watch('pageLength')
  async watchPageLength() {
    await this.getItems();
  }

  async search() {
    const data = await this.$api.employee.user.GetList(this.page, this.pageLength, this.searchTerm);
    this.$set(this, "rows", data.items);
    this.$set(this, "totalItems", data.totalItems);
  }

  async goToUser(id: string) {
    await this.$router.push({name: 'employee-user', params: {identifier: id}})
  }
}
